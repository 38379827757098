/* eslint-disable @typescript-eslint/no-untyped-public-signature */
import React from 'react';
import s from './RadioOption.scss';
import cx from 'classnames';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {IProvidedTranslationProps} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';

export interface FilterOptionProps extends IGalleryGlobalProps {
  id: string;
  label: string;
  onChange: Function;
  selected: boolean;
}

@withGlobals
export class RadioOption extends React.PureComponent<FilterOptionProps & IProvidedTranslationProps> {
  private focusedByMouse: boolean = false;
  public state = {
    focusVisible: false,
  };

  private getInputClassName(): string {
    const {
      globals: {experiments},
    } = this.props;
    if (experiments.isGalleryFiltersAccessibleRadioGroup) {
      return s.inputSrOnly;
    }
    return s.input;
  }

  private isFocusRing(): boolean {
    const {
      globals: {experiments, accessibilityEnabled},
    } = this.props;
    return experiments.isGalleryFiltersAccessibleRadioGroup && accessibilityEnabled && this.state.focusVisible;
  }

  public render() {
    const {id, label, onChange, selected} = this.props;
    const classNames = cx(
      'wixSdkShowFocusOnSibling',
      s.label,
      {[s.selected]: selected},
      {[s.focusRing]: this.isFocusRing()}
    );

    return (
      // eslint-disable-next-line
      <label
        onClick={this.handleClick}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        data-hook="label"
        className={classNames}>
        <input
          data-hook="radio-option-input"
          type="radio"
          value={id}
          checked={selected}
          onChange={(e) => onChange(e)}
          className={this.getInputClassName()}
        />
        {label}
      </label>
    );
  }

  private readonly handleClick = () => {
    this.focusedByMouse = true;
  };

  private readonly onFocus = () => {
    if (!this.focusedByMouse) {
      this.setState({focusVisible: true});
    }
  };

  private readonly onBlur = () => {
    this.setState({focusVisible: false});
    this.focusedByMouse = false;
  };
}
